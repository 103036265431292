import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { PropsWithChildren } from 'react';
import { MdExpandMore } from 'react-icons/md';

import { CompanyTag } from '@/components/atoms/CompanyTag';

interface JobTitleAccordionProps extends PropsWithChildren {
  title: string;
  companies: {
    id: number;
    name: string;
  }[];
  defaultExpanded?: boolean;
  className?: string;
}

const JobTitleAccordion = ({
  title,
  companies,
  defaultExpanded,
  children,
}: JobTitleAccordionProps) => {
  const uniqueCompanies = [
    ...new Map(companies.map((company) => [company.id, company])).values(),
  ];

  return (
    <AccordionPrimitive.Root
      type="multiple"
      className="w-full rounded-[10px]"
      defaultValue={defaultExpanded ? ['1'] : []}
    >
      <AccordionPrimitive.Item
        value="1"
        className="w-full rounded-[10px] bg-white"
      >
        <AccordionPrimitive.Header className="relative w-full rounded-[10px] bg-white">
          <AccordionPrimitive.Trigger className="group flex w-full grow rounded-[10px] px-5 py-[18px]">
            <div className="flex w-full">
              <h3 className="whitespace-nowrap text-left text-base font-medium text-[#4A4A4A]">
                {title}
              </h3>
              <div className="ml-auto flex w-full items-center justify-end gap-4">
                <div className="flex items-center gap-2">
                  {uniqueCompanies.map((company) => (
                    <CompanyTag companyName={company.name} key={company.id} />
                  ))}
                </div>
                <MdExpandMore className=" text-3xl text-primary transition-transform duration-300 ease-[cubic-bezier(0.87,_0,_0.13,_1)] group-data-[state=open]:rotate-180" />
              </div>
            </div>
          </AccordionPrimitive.Trigger>
        </AccordionPrimitive.Header>
        <AccordionPrimitive.Content className="overflow-hidden rounded-[10px] bg-white px-5 text-[15px] data-[state=closed]:animate-slideUp data-[state=open]:animate-slideDown">
          <section className="flex flex-col gap-2 pb-4 pt-[10px]">
            {children}
          </section>
        </AccordionPrimitive.Content>
      </AccordionPrimitive.Item>
    </AccordionPrimitive.Root>
  );
};

export default JobTitleAccordion;
