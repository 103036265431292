import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';

import { MassEvidenceExtractionButton } from '@/components/atoms/MassEvidenceExtractionButton';
import NoPermissionText from '@/components/atoms/NoPermissionText';

import EmptyIcon from '../../assets/icons/empty.svg';
import Cards from '../../components/molecules/Cards';
import ExpandableSubtitle from '../../components/molecules/ExpandableSubtitle';
import SideFilter from '../../components/molecules/SideFilter';
import { TrainingControlDownloadButton } from '../../components/molecules/TrainingControlDownloadButton';
import { TrainingControlHistoryDownloadButton } from '../../components/molecules/TrainingControlHistoryDownloadButton';
import { TrainingControlUploadButton } from '../../components/molecules/TrainingControlUploadButton';
import ViewScheduleWrapper from '../../components/molecules/ViewScheduleWrapper';
import InfiniteTable from '../../components/organisms/InfiniteTable';
import { filters } from '../../constants/filters/TrainingControlFilter';
import { columns } from '../../constants/tableColumns/TrainingControlColumns';
import api from '../../services/apiSgft';
import { trainingControlAtom } from '../../state/TrainingControl.atom';
import { Indicators, TrainingControl } from '../../types/TrainingControl';
import {
  userCanSeeTrainingControl,
  userCanSeeTrainingControlButtons,
} from '../../utils/handleSavePermissions';
import { TrainingControlTemplateButton } from './TrainingControlTemplateButton/TrainingControlTemplateButton';

const TrainingControlPage = () => {
  const filterValues = useRecoilValue(trainingControlAtom);
  const [filteredData, setFilteredData] = useState(filterValues);
  const [indicators, setIndicators] = useState<Indicators>();
  const isRhOrAdmin = userCanSeeTrainingControlButtons();

  const fetchControl = async ({
    pageParam = 1,
  }): Promise<{
    count: number;
    nextPage: number | null;
    trainingsControl: TrainingControl[];
  }> => {
    try {
      const response = await api.get(`training-control`, {
        params: {
          size: 20,
          page: pageParam,
          employee: filteredData.employee,
          role: filteredData.role,
          rmStatus: filteredData.rmStatus,
          training: filteredData.training,
          presencialCompletionStatus: filteredData.presencialCompletionStatus,
          onlineCompletionStatus: filteredData.onlineCompletionStatus,
          expirationStatus: filteredData.expirationStatus,
          management: filteredData.management,
          areaCoordinator: filteredData.areaCoordinator,
          pole: filteredData.pole,
          workstation: filteredData.workstation,
          trainingType: filteredData.trainingType,
          trainingPole: filteredData.trainingPole,
        },
      });
      return response.data;
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery(['training-control', filteredData], fetchControl, {
    retry: false,
    getNextPageParam: (actualPage) => {
      return actualPage.nextPage;
    },
  });

  const handleApplyFilter = (isReseting: boolean) => {
    if (isReseting)
      setFilteredData(() => ({
        employee: [],
        role: [],
        rmStatus: [],
        training: [],
        presencialCompletionStatus: [],
        onlineCompletionStatus: [],
        expirationStatus: [],
        management: [],
        areaCoordinator: [],
        trainingType: [],
        trainingPole: [],
        pole: [],
        workstation: [],
      }));
    else {
      setFilteredData(() => ({
        ...filterValues,
      }));
    }
  };

  const trainingsControl = data?.pages
    ? data.pages.flatMap((page) => page.trainingsControl || [])
    : [];

  const filteredFilters = isRhOrAdmin
    ? filters
    : filters.filter((filter) => filter.key !== 'management');

  useEffect(() => {
    const getIndicators = async () => {
      try {
        const response = await api.get('training-control/indicators', {
          params: {
            employee: filteredData.employee,
            role: filteredData.role,
            rmStatus: filteredData.rmStatus,
            training: filteredData.training,
            presencialCompletionStatus: filteredData.presencialCompletionStatus,
            onlineCompletionStatus: filteredData.onlineCompletionStatus,
            expirationStatus: filteredData.expirationStatus,
            management: filteredData.management,
            areaCoordinator: filteredData.areaCoordinator,
            trainingType: filteredData.trainingType,
            trainingPole: filteredData.trainingPole,
            pole: filteredData.pole,
            workstation: filteredData.workstation,
          },
        });
        setIndicators(response.data);
      } catch (e) {
        toast.error('Erro ao carregar indicadores', {
          theme: 'colored',
          toastId: 'error',
        });
        throw e;
      }
    };
    getIndicators();
  }, [filteredData]);

  if (!userCanSeeTrainingControl()) return <NoPermissionText />;

  return (
    <div className="relative flex w-full flex-col items-start">
      {isRhOrAdmin && (
        <div className="absolute -top-10 right-20 flex items-center">
          <TrainingControlTemplateButton />
          <TrainingControlUploadButton />
          <TrainingControlDownloadButton filteredData={filteredData} />
          <TrainingControlHistoryDownloadButton filteredData={filteredData} />
          <MassEvidenceExtractionButton />
        </div>
      )}
      <SideFilter
        refetchOnChange
        filters={filteredFilters}
        atom={trainingControlAtom}
        applyChanges={handleApplyFilter}
      />
      <ViewScheduleWrapper isError={isError} isLoading={isLoading}>
        <div className="w-[96%]">
          {indicators?.topTrainings && (
            <ExpandableSubtitle
              subtitle={'Pendências por Treinamento'}
              hasTooltip
              tooltipText="Top 5 dos treinamentos com maiores quantidades de treinamentos com situação de vencimento igual a vencido, inválido ou pendente"
            >
              {indicators?.topTrainings.length > 0 ? (
                <Cards
                  cards={indicators?.topTrainings.map((el) => ({
                    value: el.count,
                    status: 'grey',
                    title: el.name,
                  }))}
                />
              ) : (
                <div className="p-2 font-graphie text-[15px] text-[#4A4A4A]">
                  Não existem treinamentos com pendências
                </div>
              )}
            </ExpandableSubtitle>
          )}
          {indicators?.statusCounts && (
            <ExpandableSubtitle subtitle={'Treinamentos por Situação'}>
              <Cards
                cards={[
                  {
                    value: indicators.statusCounts.Pendentes,
                    status: 'red',
                    title: 'Pendentes',
                  },
                  {
                    value: indicators.statusCounts.Invalidos,
                    status: 'red',
                    title: 'Invalidados',
                  },
                  {
                    value: indicators.statusCounts.Vencidos,
                    status: 'red',
                    title: 'Vencidos',
                  },
                  {
                    value: indicators.statusCounts.VenceEm1Mes,
                    status: '#dbdb00',
                    title: 'Vence em 1 mês',
                  },
                  {
                    value: indicators.statusCounts.VenceEm3Meses,
                    status: '#dbdb00',
                    title: 'Vence em 3 meses',
                  },
                  {
                    value: indicators.statusCounts.Validos,
                    status: 'green',
                    title: 'Válidos',
                  },
                ]}
              />
            </ExpandableSubtitle>
          )}
        </div>
        <div className="flex h-full w-full flex-col overflow-hidden rounded-md bg-white px-5 py-2">
          {trainingsControl?.length > 0 ? (
            <InfiniteTable
              hasNextPage={hasNextPage}
              data={trainingsControl}
              columns={columns}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
            />
          ) : (
            <div className="flex h-full w-full flex-col items-center justify-center rounded-md bg-white">
              <img src={EmptyIcon} className="w-40" />
              <div className="w-full text-center text-sm">
                <p>Nenhum treinamento foi encontrado com esses parâmetros</p>
                <span className="font-semibold text-blue-800">
                  Cadastre um ou altere os valores dos filtros para visualizar a
                  tabela!
                </span>
              </div>
            </div>
          )}
        </div>
      </ViewScheduleWrapper>
    </div>
  );
};

export default TrainingControlPage;
