import { UserTypes } from '../constants/SGFTUserTypes';
import { msalInstance } from '../main';

export const userCanSave = (
  selectedUserManagement: number | null,
  actualManagement: number,
) => {
  const roles = getActualRole();

  if (roles?.includes(UserTypes.Admin)) return true;
  if (roles?.includes(UserTypes.Rh)) return true;
  if (roles?.includes(UserTypes.Manager)) return true;
  return actualManagement === selectedUserManagement;
};

export const userIsRhOrAdmin = () => {
  const roles = getActualRole();

  if (roles?.includes(UserTypes.Admin)) {
    return true;
  }

  return roles?.includes(UserTypes.Rh);
};

export const userCanSaveTraining = () => {
  const roles = getActualRole();

  if (roles?.includes(UserTypes.Admin)) {
    return true;
  }
  return roles?.includes(UserTypes.QsmsTreinamento);
};

export const userCanSeeTraining = () => {
  const roles = getActualRole();

  if (roles?.includes(UserTypes.Admin)) {
    return true;
  }
  return (
    roles?.includes(UserTypes.QsmsTreinamento) ||
    roles?.includes(UserTypes.RhTreinamento)
  );
};

export const userCanSeeTrainingControl = () => {
  const roles = getActualRole();

  if (
    roles?.includes(UserTypes.Admin) ||
    roles?.includes(UserTypes.QsmsTreinamento) ||
    roles?.includes(UserTypes.RhTreinamento)
  ) {
    return true;
  }
  if (roles?.includes(UserTypes.ReadOnly)) {
    return false;
  }
  return true;
};

export const userCanSeeTrainingControlButtons = () => {
  const roles = getActualRole();

  if (
    roles?.includes(UserTypes.Admin) ||
    roles?.includes(UserTypes.QsmsTreinamento) ||
    roles?.includes(UserTypes.RhTreinamento)
  ) {
    return true;
  }
  if (roles?.includes(UserTypes.ReadOnly)) {
    return false;
  }
  return false;
};

export const userCanSeeTeamStructureForms = () => {
  const roles = getActualRole();
  if (roles?.includes(UserTypes.ReadOnly)) {
    return false;
  }
  if (roles?.includes(UserTypes.Admin)) {
    return true;
  }
  return roles?.includes(UserTypes.Rh);
};

export const getActualRole = () => {
  return msalInstance
    ? msalInstance?.getActiveAccount()?.idTokenClaims?.roles
    : [];
};

export const isInRole = (role: string) => {
  return getActualRole()?.includes(role);
};

export const isReadOnlyUser = () => {
  const roles = getActualRole();
  return roles?.includes(UserTypes.ReadOnly);
};

export const shouldDisableScheduleMenu = () => {
  const roles = getActualRole();

  if (roles?.includes(UserTypes.OnDutyWorker)) {
    return false;
  }

  return roles?.includes(UserTypes.ReadOnly);
};

export const isOnDutyWorker = () => {
  const roles = getActualRole();
  return roles?.includes(UserTypes.OnDutyWorker);
};

export const userCanSaveAllocation = () => {
  const roles = getActualRole();

  if (roles?.includes(UserTypes.Admin)) {
    return true;
  }

  return roles?.includes(UserTypes.Rh);
};

export const isAdmin = () => {
  return getActualRole()?.includes(UserTypes.Admin);
};

export const userCanEditRequirementControl = () => {
  const roles = getActualRole();

  if (roles?.includes(UserTypes.Admin)) {
    return true;
  }

  return roles?.includes(UserTypes.Rh);
};

export const isRegulatorio = () => {
  return getActualRole()?.includes(UserTypes.Regulatorio);
};

export const userCanSeeRequirementControl = () => {
  const roles = getActualRole();

  if (roles?.includes(UserTypes.Admin)) {
    return true;
  }

  return roles?.includes(UserTypes.Rh);
};
